<template v-once>
  <img src="@/assets/icons/ihm_icon.jpg" alt="international healthcare media icon">
</template>

<script>
export default {
  name: "Ihm",
};
</script>

<style scoped>
</style>