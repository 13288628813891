import axios from "axios";
import store from "../store/index";
import router from "@/router/router";

export const rest = (function() {
  // Create instance of axios
  let HTTP = axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_REST_ADDRESS,
    withCredentials: true
  });

  // Configure interceptors
  HTTP.interceptors.request.use(config => {
    localStorage.setItem("authenticated", "true");
    if (!config.hide_loader) {
      store.commit("startLoading");
    }

    if (config.headers && (config.headers['X-Account'] ?? null)) {
      // The X-Account header was already set for the request
      return config;
    }

    if (store.getters["user/account"].accountId) {
      config.headers["X-Account"] = store.getters["user/account"].accountId;
    } else if (localStorage.getItem("account")) {
      config.headers["X-Account"] = JSON.parse(
        localStorage.getItem("account")
      ).accountId;
    }
    return config;
  });
  HTTP.interceptors.response.use(
    response => {
      if (!response.config?.hide_loader) {
        store.commit("stopLoading");
      }

      if (!response.config?.doNotUpdateLastActivity) {
        // Update the last activity so that the user doesn't get auto-logged out
        store.commit('user/updateLastActivityDateTime', new Date());
      }

      return response;
    },
    async error => {
      if (!error?.response?.config?.hide_loader) {
        store.commit("stopLoading");
      }

      if (error.response.status === 401
          && error.response.url !== "login_check"
      ) {
        localStorage.setItem("authenticated", "false");
        localStorage.removeItem("account");
        localStorage.removeItem('user');
        store.commit('user/clearLogoutCheck');
      }

      /*
       * User notification on failures */
      if (error.response.status === 401) {
        if (localStorage.getItem("manualLogout")) {
          store.commit("snackbar/showMessage", {
            content: "You have been logged out.",
            color: "success"
          });
        } else if (!localStorage.getItem("authenticated")) {
          store.commit("snackbar/showMessage", {
            content: "Your session has expired.",
            color: "error"
          });
        }

        if (!localStorage.getItem("manualLogout") && router.currentRoute.fullPath
            && router.currentRoute.name !== 'Login' && router.currentRoute.name !== 'Accounts'
            && router.currentRoute.fullPath !== 'undefined' && router.currentRoute.fullPath !== '/'
            && router.currentRoute.name !== "PhoneConfirm" && router.currentRoute.name !== "PhoneCodeConfirm"
        ) {
          //Because the Date object is not implemented in JS, but provided by the browser.
          // And each browser uses its own library for working with time.
          let date = new Date()
          date.setUTCHours(date.getUTCHours() + 3);
          // `item` is an object which contains the original value
          // as well as the time when it's supposed to expire
          let redirectPageData = [];
          try {
            redirectPageData = JSON.parse(localStorage.getItem('redirectPage'));
          } catch (e) {
            redirectPageData = null;
          }
          if (redirectPageData && redirectPageData.expiry < Date.now()) {
            localStorage.removeItem('redirectPage');
          }
          if (localStorage.getItem("redirectPage") === undefined || localStorage.getItem("redirectPage") === null) {
            const item = {
              value: router.currentRoute.fullPath,
              expiry: date.getTime(),
            }
            localStorage.setItem("redirectPage", JSON.stringify(item))
          }
        }

        localStorage.removeItem("defaultDashboard");
        localStorage.removeItem("manualLogout");
        store.commit('user/clearLogoutCheck');

        if (router.currentRoute.name !== 'Login') {
          await router.push({ name: "Login" });
        }
      } else if (error.response?.data && !error.response.config?.hide_error) {
        let error_message = null;
        let errors_list = "";
        if (error.response?.data?.errors?.length) {
          error.response.data.errors.forEach(item => {
            errors_list += (item.error + "\r \n");
          });
          error_message = errors_list;
        } else if (error.response?.data?.message) {
          error_message = error.response?.data?.message;
        }

        store.commit("snackbar/showMessage", {
          content: error_message || "Something went wrong",
          color: "error"
        });
      }

      return Promise.reject(error);
    }
  );

  return {
    login: {
      authorize(email, password) {
        return HTTP.post("login_check", { email, password });
      },
      request_password_change(email) {
        return HTTP.post("password/request_reset", { email });
      },
      reset_password(password, token) {
        return HTTP.post(`password/reset/${token}`, { password });
      },
      reset_token(refresh_token) {
        return HTTP.post("token/refresh", { refresh_token });
      },
      logout() {
        localStorage.setItem("authenticated", "false");
        localStorage.removeItem("defaultDashboard");

        return HTTP.get("logout");
      },
      resetRememberMe() {
        return HTTP.post("user/reset_remember_me");
      },
      refresh() {
        return HTTP.post('/token/refresh', null, {
          doNotUpdateLastActivity: true,
          hide_loader: true,
        });
      },
    },
    user: {
      get_user_details() {
        return HTTP.get("user/me");
      },
      get_user_companies() {
        return HTTP.get("user_companies");
      },

      /**
       * @body -
       *      "email": "example@gmail.com",
       *      "firstName": "first name",
       *      "lastName": "last name",
       *      "plainPassword": "plainPassword",
       * */
      put_resource(body) {
        return HTTP.put(`user/me`, { ...body });
      },

      /**
       * @body - any email preferences
       * */
      put_email_preferences(accountId, body) {
        return HTTP.put(`user/account/${accountId}/email_preferences`, {
          ...body
        });
      },

      set_default_dashboard(id) {
        return HTTP.put(`users/setDefaultDashboard/${id}`);
      },

      getUserAccounts(userId) {
        return HTTP.get(`users/${userId}/accounts`);
      },
      addUserAccount(userId, accountId, data) {
        return HTTP.post(`user/${userId}/account/${accountId}`, {
          ...data
        });
      },
      deleteUserAccount(userId, accountId) {
        return HTTP.delete(`user/${userId}/account/${accountId}`);
      },
      phoneValidation(data) {
        return HTTP.post(`user/send_verification_code`, {
          ...data
        });
      },
      phoneCodeValidation(data) {
        return HTTP.post(`user/verification_code`, {
          ...data
        });
      },
    },
    admin: {
      get_collection(query) {
        return HTTP.get("users", { params: query });
      },
      get_resource(id) {
        return HTTP.get(`users/${id}`);
      },

      /**
       * all field require for post method
       * @body -
       *      "email": "example@gmail.com",
       *      "firstName": "first name",
       *      "lastName": "last name",
       *      "plainPassword": "plainPassword",
       * */
      post_resource(data) {
        return HTTP.post(`users`, { ...data });
      },

      /**
       * @body -
       *      "email": "example@gmail.com",
       *      "firstName": "first name",
       *      "lastName": "last name",
       *      "plainPassword": "plainPassword",
       * */
      put_resource(id, body) {
        return HTTP.put(`users/${id}`, { ...body });
      },

      delete_resource(id) {
        return HTTP.delete(`users/${id}`);
      },

      post_user_to_account(id, accountId) {
        return HTTP.post(`user/${id}/account/${accountId}`);
      },

      delete_user_to_account(id, accountId) {
        return HTTP.delete(`user/${id}/account/${accountId}`);
      },

      getDatabaseCollection(query) {
        return HTTP.get("databases", { params: query });
      },
      getDatabase(id) {
        return HTTP.get(`database/${id}`);
      },

      postDatabaseResource(data) {
        return HTTP.post("databases", { ...data });
      },

      put_database_resource(id, data) {
        return HTTP.put(`databases/${id}`, { ...data });
      },

      deleteDatabaseResource(id) {
        return HTTP.delete(`databases/${id}`);
      },
      reset_login_attempts(id) {
        return HTTP.post(`user/reset_failed_attempts/${id}`);
      }
    },
    account: {
      get_collection(query) {
        return HTTP.get("accounts", { params: query });
      },
      get_resource(id) {
        return HTTP.get(`accounts/${id}`);
      },
      post_resource(data) {
        return HTTP.post(`accounts`, data);
      },
      put_resource(id, data) {
        return HTTP.put(`accounts/${id}`, data);
      },
      select_account(id) {
        return HTTP.put(`/users/selectAccount/${id}`);
      },
      get_custom_field_constraints() {
        return HTTP.get('/custom_field_constraints');
      },
      put_personalization_settings_resource(id, data) {
        return HTTP.put(`accounts/${id}/personalization_settings`, data);
      },
      get_account_requests_collection(query) {
        return HTTP.get("account_requests", { params: query });
      },
      approved_account_request(id) {
        return HTTP.post(`account_requests/${id}/approved`);
      },
      put_account_request(id, data) {
        return HTTP.put(`account_requests/${id}`, data);
      },
      getAgencies() {
        return HTTP.get("account_agencies");
      },
    },
    actions: {
      emails(params) {
        return HTTP.get(
          "/actions/email",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      events(params) {
        return HTTP.get(
          "/actions/event",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      banners(params) {
        return HTTP.get(
          "/actions/banner",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      directMails(params) {
        return HTTP.get(
          "/actions/direct_mail",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      web(params) {
        return HTTP.get(
          "/actions/web",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      forms(params) {
        return HTTP.get(
          "/actions/form",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      phone(params) {
        return HTTP.get(
          "/actions/phone",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      alert(params) {
        return HTTP.get(
          "/actions/alert",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      prescription(params) {
        return HTTP.get(
          "/actions/prescription",
          {
            params,
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
    },
    action_codes: {
      getCollection(params) {
        return HTTP.get("/action_codes", { params });
      }
    },
    contact: {
      check_email(email) {
        return HTTP.get("/contact_emails", { params: { emailAddress: email } });
      },
      bulk_subscribe(data) {
        return HTTP.post("/contact_emails/bulk_subscribe", { ...data });
      },
      bulk_unsubscribe(data) {
        return HTTP.post("/contact_emails/bulk_unsubscribe", { ...data });
      },
      /** Get a list with contacts
       *  @limit - numeric input form limit items in one page
       * @page - identify wich page need to load
       * @returns - {entities and number of overal entities}
       * */
      get_collection(limit, page, sort) {
        return HTTP.post("contacts/search", "", {
          params: {
            groups: ["contact_default_location", "contact_display_email"],
            limit,
            page,
            sort
          }
        });
      },
      /**
       * @fields - Array of fields which need to persists in resulting answer
       * @body - object representation of search parameters
       *      "searchId": 5,
				    "criteria": {
				        "operator": "and",
				        "groups": [
				            {
				                "operator": "and",
				                "conditions": [
				                    {
				                        "field": "contact.customFields.clientId",
				                        "operator": "is equal to",
				                        "value": 53611
				                    }
				                ]
				            }
				        ]
				    }
       * */
      search_collection(params, body) {
        return HTTP.post(
          "contacts/search",
          { ...body },
          {
            params,
            /* Hide the page loading indicator for API requests that just update the search result count */
            hide_loader: params.collectionProperties?.includes('totalCount')
          }
        );
      },
      get_saved_search(id) {
        return HTTP.get(`contacts/search/${id}`);
      },
      get_contact(id, params, hide_loader = false) {
        return HTTP.get(`contacts/${id}`, {
          params: { ...params },
          hide_loader: hide_loader
        });
      },
      get_contact_search(id) {
        return HTTP.get(`contacts/search/${id}`);
      },
      get_contact_segments(id, params) {
        return HTTP.get(`contacts/${id}/segments`, { params });
      },
      get_contact_actions(id, params) {
        return HTTP.get(`contacts/${id}/actions`, { params });
      },
      get_contact_statistics(id) {
        return HTTP.get(`contacts/${id}/statistics`);
      },
      get_contact_action_dates(id, params) {
        return HTTP.get(`contacts/${id}/actions_dates`, {
          params,
        });
      },
      /**Need to create new user
       * @data - user model representation
       * {
					"salutation": "",
					"firstName": "Andy",
					"middleName": "",
					"lastName": "Rodriguez",
					"credentials": "",
					"source": 1,
				    "customFields": {
				        "clientId": 123456
				    }
				}
       * */
      post_contact(data) {
        return HTTP.post(`contacts`, data);
      },
      validate_contact(data) {
        return HTTP.post(`contacts/validate`, data);
      },
      /**
       *
       * @param id
       * @param data - incremental contact model update
       * @returns {Promise<AxiosResponse<any>>}
       */
      put_contact(id, data) {
        return HTTP.put(`contacts/${id}`, { ...data });
      },
      /**
       * @param id
       * @param {FormData} formData
       *
       * @returns {Promise<AxiosResponse<any>>}
       */
      fput_contact(id, formData) {
        return HTTP.post(`contacts/${id}?_method=PUT`, formData, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      },
      /**
       *
       * @param id
       * @returns {Promise<AxiosResponse<any>>}
       */
      delete_contact(id) {
        return HTTP.delete(`contacts/${id}`);
      },
      delete_contact_locations(id) {
        return HTTP.delete(`contact_locations/${id}`);
      },
      delete_contact_phones(id) {
        return HTTP.delete(`contact_phones/${id}`);
      },
      delete_contact_emails(id) {
        return HTTP.delete(`contact_emails/${id}`);
      },
      get_search_fields() {
        return HTTP.get(`contact_search_fields`);
      },
      getEditableCustomFields() {
        return HTTP.get(`contact_edit_fields`);
      },
      getAllContacts(data) {
        return HTTP.get(`contacts`, {
          params: data,
        });
      },
    },
    segment: {
      get_collection(params, options) {
        return HTTP.get("segments", { params, ...options });
      },
      get_resource(id) {
        return HTTP.get(`segments/${id}`);
      },
      post_resource(body) {
        return HTTP.post("segments", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`segments/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`segments/${id}`);
      },
      get_contacts(id, body) {
        return HTTP.get(`segments/${id}/contacts`, { ...body });
      },
      get_count(id) {
        return HTTP.get(`segments/${id}/count`);
      },
      add_to_segment(id, body) {
        return HTTP.post(`segments/${id}/contacts`, { ...body });
      },
      remove_from_segment(id, contactId) {
        return HTTP.delete(`segments/${id}/contacts/${contactId}`);
      },
      get_segment_statistics(id) {
        return HTTP.get(`segments/${id}/statistic`);
      },
      get_segment_for_leadscore() {
        return HTTP.get(`segments-leadscore`);
      }
    },
    campaign: {
      get_collection(params) {
        return HTTP.get("campaigns", { params });
      },
      get_resource(id) {
        return HTTP.get(`campaigns/${id}?groups[]=draft_config&includeStatistics=true`);
      },
      get_resource_email_subjects(id, params) {
        return HTTP.get(`campaigns/${id}/email_subjects`, { params });
      },
      post_resource(body) {
        return HTTP.post("campaigns", { ...body });
      },
      estimate_counts(body) {
        return HTTP.post("campaigns/estimate_counts", {
          nodeConfiguration: { ...body }
        });
      },
      put_resource(id, body) {
        return HTTP.put(`campaigns/${id}`, { ...body });
      },
      clone_resource(id, name) {
        return HTTP.post(`campaigns/${id}/clone`, { name });
      },
      delete_resource(id) {
        return HTTP.delete(`campaigns/${id}`);
      },
      getEmailSubjects(params) {
        return HTTP.get(`campaigns/email_subjects`, { params });
      },
      get_nodes(id) {
        return HTTP.get(`campaigns/${id}/nodes`);
      },
    },
    source: {
      get_collection(params) {
        return HTTP.get("sources", { params });
      },
      get_resource(id) {
        return HTTP.get(`sources/${id}`);
      },
      post_resource(body) {
        return HTTP.post("sources", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`sources/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`sources/${id}`);
      }
    },
    fromlines: {
      get_collection(params) {
        return HTTP.get("fromlines", { params });
      },
      get_resource(id) {
        return HTTP.get(`fromlines/${id}`);
      },
      get_fromline_dns(id, params) {
        return HTTP.get(`fromlines/${id}/dns`, { params });
      },
      post_resource(body) {
        return HTTP.post("fromlines", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`fromlines/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`fromlines/${id}`);
      },
      verify_fromline(id) {
        return HTTP.post(`fromlines/sent_verification/${id}`);
      },
      verify_fromline_with_token(token, options) {
        return HTTP.put(`fromlines/verification/${token}`, null, options);
      }
    },
    creative: {
      email: {
        get_collection(params) {
          return HTTP.get("email_messages", { params });
        },
        get_resource(id, params) {
          return HTTP.get(`email_messages/${id}`, { params });
        },
        post_resource(body) {
          return HTTP.post("email_messages", { ...body });
        },
        put_resource(id, body) {
          return HTTP.put(`email_messages/${id}`, { ...body });
        },
        clone_resource(id, name) {
          return HTTP.post(`email_messages/${id}/clone`, { name });
        },
        upload_zip_resource(id, formData) {
          return HTTP.post(`email_messages/${id}/upload-zip`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
            hide_loader: true,
          });
        },
        delete_resource(id) {
          return HTTP.delete(`email_messages/${id}`);
        },
        sendTestEmail(data) {
          return HTTP.post(`/email_messages/send_test`, data);
        },
        getTags(id, params) {
          return HTTP.get(`email_messages/${id}/tags`, { params });
        },
        get_email_in_browser(accountId, campaignTrackingToken, emailToken) {
          return HTTP.get(`view_in_browser/${campaignTrackingToken}/${emailToken}`, {
            headers: {
              "X-Account": accountId,
            }
          });
        },
        render_html(data) {
          return HTTP.post(`/email_messages/render_html`, data);
        }
      }
    },
    ai: {
      getPromptsCollection(params) {
        return HTTP.get("ai/prompts", { params });
      },
      generatePromptResponse(promptKey, data, config) {
        return HTTP.post(`ai/prompts/${promptKey}/generate`, data, { ...config });
      },
      testPrompt(data, config) {
        return HTTP.post(`/ai/prompts/test`, data, { ...config });
      },
      updatePrompt(id, data) {
        return HTTP.put(`/ai/prompts/${id}`, data);
      },
    },
    dashboards: {
      get_collection(params) {
        return HTTP.get("dashboards", { params });
      },
      get_resource(id, hide_loader = false) {
        return HTTP.get(`dashboards/${id}`, {
          hide_loader,
        });
      },
      post_resource(body) {
        return HTTP.post("dashboards", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`dashboards/${id}`, { ...body });
      },
      delete_resource(id, params) {
        return HTTP.delete(`dashboards/${id}`, { params });
      },
      clone_resource(id, name) {
        return HTTP.post(`dashboards/${id}/clone`, { name });
      },
      add_widget(dashboardId, widget) {
        return HTTP.post(`dashboards/${dashboardId}/widgets`, widget);
      },
      update_widget(widget) {
        return HTTP.put(`dashboards/widgets/${widget.id}`, {...widget});
      },
      get_widgets(id, hide_loader = false) {
        return HTTP.get(`dashboards/${id}/widgets`, {
          hide_loader,
        });
      },
      get_dashboard_widget_data(id, hide_loader = false) {
        return HTTP.get(`dashboards/widgets/${id}/data`, {
          hide_loader,
        });
      },
      get_available_widgets() {
        return HTTP.get("widgets");
      },
      delete_widget(id) {
        return HTTP.delete(`dashboards/widgets/${id}`);
      }
    },
    blacklists: {
      get_collection(params) {
        return HTTP.get("blacklist", { params });
      },
      get_resource(id) {
        return HTTP.get(`blacklist/${id}`);
      },
      post_resource(body) {
        return HTTP.post("blacklist", { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`blacklist/${id}`);
      }
    },
    banners: {
      get_collection(params) {
        return HTTP.get("banners", { params });
      },
      get_resource(id) {
        return HTTP.get(`banners/${id}`);
      },
      post_resource(data) {
        return HTTP.post("banners", data);
      },
      put_resource(id, data) {
        return HTTP.put(`banners/${id}`, data);
      },
      delete_resource(id) {
        return HTTP.delete(`banners/${id}`);
      },
      getTags(bannerId, params) {
        return HTTP.get(`banners/${bannerId}/tags`, { params });
      },
    },
    directmails: {
      get_collection(params) {
        return HTTP.get("direct_mail", { params });
      },
      get_resource(id) {
        return HTTP.get(`direct_mail/${id}`);
      },
      post_resource(data) {
        return HTTP.post("direct_mail", data);
      },
      put_resource(id, data) {
        return HTTP.put(`direct_mail/${id}`, data);
      },
      put_image(id, image, headers) {
        return HTTP.put(`banners/${id}`, image, { headers });
      },
      delete_resource(id) {
        return HTTP.delete(`direct_mail/${id}`);
      },
      getTags(directMailId, params) {
        return HTTP.get(`direct_mail/${directMailId}/tags`, { params });
      },
    },
    events: {
      get_collection(params) {
        return HTTP.get("events", { params });
      },
      get_resource(id) {
        return HTTP.get(`events/${id}`);
      },
      post_resource(body) {
        return HTTP.post("events", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`events/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`events/${id}`);
      },
      getTags(eventId, params) {
        return HTTP.get(`events/${eventId}/tags`, { params });
      },
    },
    alerts: {
      get_collection(params) {
        return HTTP.get("alerts", { params });
      },
      get_resource(id) {
        return HTTP.get(`alerts/${id}`);
      },
      post_resource(body) {
        return HTTP.post("alerts", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`alerts/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`alerts/${id}`);
      },
      getTags(id, params) {
        return HTTP.get(`alerts/${id}/tags`, { params });
      },
    },
    forms: {
      get_collection(params) {
        return HTTP.get("forms", { params });
      },
      get_resource(id) {
        return HTTP.get(`forms/${id}`);
      },
      post_resource(body) {
        return HTTP.post("forms", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`forms/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`forms/${id}`);
      },
      getTags(eventId, params) {
        return HTTP.get(`forms/${eventId}/tags`, { params });
      },
    },
    exports: {
      get_collection(params) {
        return HTTP.get("exports", { params });
      },
      get_resource(id) {
        return HTTP.get(`exports/${id}`);
      },
      post_resource(body) {
        return HTTP.post("exports", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`exports/${id}`, { ...body });
      },
      get_export_types(params) {
        return HTTP.get("export_types", { params });
      },
      get_export_type(type) {
        return HTTP.get(`export_types/${type}`);
      },
    },
    imports: {
      get_collection(params) {
        return HTTP.get("imports", { params });
      },
      get_resource(id) {
        return HTTP.get(`imports/${id}`);
      },
      post_resource(body) {
        return HTTP.post("imports", body, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      },
      put_resource(id, body) {
        return HTTP.put(`imports/${id}`, { ...body });
      },
      get_import_rows(id, params) {
        return HTTP.get(`imports/${id}/import_rows`, { params });
      },
      put_import_row(id, body) {
        return HTTP.put(`import_rows/${id}`, { ...body });
      }
    },
    remote_connection: {
      test(body) {
        return HTTP.post("remote_connection_test", { ...body });
      }
    },
    plugins: {
      getPluginDataById(id, params) {
        return HTTP.get(`plugins/${id}`, { params });
      },
      get_plugins(params) {
        return HTTP.get("plugins", { params });
      },
      get_clicksend_phone_numbers() {
        return HTTP.get("/plugin/clicksend/numbers");
      },
      get_clicksend_list() {
        return HTTP.get("/plugin/clicksend/lists");
      },
      get_account_plugins(params) {
        return HTTP.get("account_plugins", { params });
      },
      post_resource(body) {
        return HTTP.post("account_plugins", { ...body });
      },
      get_resource(id, params) {
        return HTTP.get(`account_plugins/${id}`, { params });
      },
      put_resource(id, body) {
        return HTTP.put(`account_plugins/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`account_plugins/${id}`);
      },
      googleAnalytics: {
        getAccountSummary(params) {
          return HTTP.get('plugin/google_analytics/account_summary', { params })
        },
        getUser(params) {
          return HTTP.get('plugin/google_analytics/user', { params })
        },
        setAuthCode(params) {
          return HTTP.post('plugin/callback_google_analytics', params)
        },
        getReportData(params) {
          return HTTP.get('plugin/google_analytics', { params })
        },
      },
      notification: {
        getEvents(params) {
          return HTTP.get('plugin/notifications/events', { params })
        },
        getMethods(params) {
          return HTTP.get('plugin/notifications/methods', { params })
        },
        getCollection(params) {
          return HTTP.get('plugin/notifications', { params })
        },
      },
      prescriptionData: {
        getReportData(params) {
          return HTTP.get('plugin/prescription_data/report', { params });
        },
      },
      repManagement: {
        getReportData(params) {
          return HTTP.get('plugin/rep_management/report', { params })
        },
        updateContacts() {
          return HTTP.get('plugin/rep_management/update_contacts')
        },
      },
      twilio: {
        getNumbers(params) {
          return HTTP.get('plugin/twilio/numbers', { params });
        },
      },
      typeform: {
        getWorkspaces(params) {
          return HTTP.get('plugin/typeform/workspaces', { params });
        },
        getForm(id, params) {
          return HTTP.get('plugin/typeform/forms/' + id, { params });
        },
        getFormPageDetails(id, params) {
          const url = id ? ('plugin/typeform/form_page_details/' + id) : 'plugin/typeform/form_page_details';
          return HTTP.get(url, { params });
        },
      },
      veeva: {
        getIdentity(params) {
          return HTTP.get('plugin/veeva/identity', { params });
        },
      }
    },
    unsubscribe: {
      getUserData(emailToken, options = {}) {
        return HTTP.get(`contact_email_token/${emailToken}`, options);
      },
      post_resource(body, options = {}) {
        return HTTP.post("unsubscribe", { ...body }, options);
      },
      change_details(body, options = {}) {
        return HTTP.post("change_details", { ...body }, options);
      },
      email_page_settings(emailToken, options = {}) {
        return HTTP.get(`email_page_settings/${emailToken}`, options);
      },
    },
    rules: {
      get_available_codes() {
        return HTTP.get("/action_codes", { params: { leadScore: true } });
      },
      get_collection(params) {
        return HTTP.get("rules", { params });
      },
      get_resource(id) {
        return HTTP.get(`rules/${id}`);
      },
      /**
       * @body -
       *      "actionType": "email|event|banner",
       *      "actionCode": "action code",
       *      "creativeId": "1",
       *      "score": "1",
       *      "numActionsToScore": "1",
       *      "scoreTimeFrame": "1",
       * */
      post_resource(body) {
        return HTTP.post("rules", { ...body });
      },
      /**
       * @body - data types - exactly as here
       *      actionCode: "2"
       *      actionType: "event"
       *      creativeId: 44
       *      numActionsToScore: 22
       *      score: 2
       *      scoreTimeFrame: 120
       * */
      put_resource(id, body) {
        return HTTP.put(`rules/${id}`, { ...body });
      },

      delete_resource(id) {
        return HTTP.delete(`rules/${id}`);
      },

      reapply_rule(id) {
        return HTTP.post(`rules/${id}/apply`);
      },
    },
    lead_segments: {
      get_collection(params) {
        return HTTP.get("lead_score_segments", { params });
      },
      get_resource(id) {
        return HTTP.get(`lead_score_segments/${id}`);
      },
      /**
       * @body -data types - exactly as here
       *      "name": "Test",
       *      "minimumScore": 1,
       *      "maximumScore": 30
       * */
      post_resource(body) {
        return HTTP.post("lead_score_segments", { ...body });
      },
      /**
       * @body - data types - exactly as here
       *      "name": "Test",
       *      "minimumScore": 1,
       *      "maximumScore": 30
       * */
      put_resource(id, body) {
        return HTTP.put(`lead_score_segments/${id}`, { ...body });
      },

      delete_resource(id) {
        return HTTP.delete(`lead_score_segments/${id}`);
      },
    },
    website: {
      get_collection(params) {
        return HTTP.get("websites", { params });
      },
      get_resource(id) {
        return HTTP.get(`websites/${id}`);
      },
      /**
       * @body -
       *      "url": "http://sitename.com",
       * */
      post_resource(body) {
        return HTTP.post("websites", { ...body });
      },
      put_resource(id, body) {
        return HTTP.put(`websites/${id}`, { ...body });
      },
      delete_resource(id) {
        return HTTP.delete(`websites/${id}`);
      },
      send_email_to_developer(id, body) {
        return HTTP.post(`websites/${id}/send_tracking_code`, { ...body });
      }
    },
    tactic: {
      get_collection() {
        return HTTP.get("tactics");
      }
    },
    media_library: {
      get_collection() {
        return HTTP.get("media_library");
      },
      post_resource(formData) {
        return HTTP.post(`media_library`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
          hide_loader: true,
        });
      },
      get_collection_directory(params) {
        return HTTP.get("media_library/directory", { params });
      },
      post_resource_directory(formData) {
        return HTTP.post(`media_library/directory`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
      },
      put_resource_directory(id, body) {
        return HTTP.put(`media_library/directory`, { ...body });
      },
      delete_resource_directory(body) {
        return HTTP.delete(`media_library/directory`, { data: { ...body } });
      },
      delete_resource_asset(body) {
        return HTTP.delete("media_library", { data: {...body} });
      }
    },
    report: {
      get_email_report(params) {
        return HTTP.get('report/email', { params });
      },
      getBannerReport(data) {
        return HTTP.get('/report/banner', {
          params: data,
        });
      },
      getEngagementReport(params) {
        return HTTP.get('/report/engagement', { params });
      },
    },
    personalization_fields: {
      getCollection(params) {
        return HTTP.get('personalization_fields', { params });
      },
    },
    tags: {
      getCollection(params) {
        return HTTP.get('tags', { params });
      },
    },
    api_tokens: {
      get_resource() {
        return HTTP.get("api_tokens", {});
      },
      post_resource(body) {
        return HTTP.post("api_tokens", { ...body });
      },
      put_resource(token, body) {
        return HTTP.put(`api_tokens/${token}`, { ...body });
      },
      delete_resource(token) {
        return HTTP.delete(`api_tokens/${token}`);
      }
    },
    accountCampaigns: {
      getCollection(query) {
        return HTTP.get("account_campaigns", { params: query });
      }
    },
    npiMatch: {
      getCount(query, hideError) {
        return HTTP.get("npi_match/count", { params: query, hide_error: hideError });
      },
      getSpecialties(query) {
        return HTTP.get("npi_match/specialties", { params: query });
      },
      import(body) {
        return HTTP.post("npi_match/import", body, {
          headers: { "Content-Type": "multipart/form-data" }
        });
      },
    },
  };
})("store", "vueInstance");
export const vue_rest = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$rest = rest;
  }
};
