<template v-once>
  <img src="@/assets/icons/web_health_network_icon.png" alt="web health network icon">
</template>

<script>
export default {
  name: "WebHealthNetwork",
};
</script>

<style scoped>
</style>