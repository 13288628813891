<template v-once>
  <img src="@/assets/icons/pulse_ai_icon.png" alt="pulse ai icon">
</template>

<script>
export default {
  name: "PulseAI",
};
</script>

<style scoped>
</style>